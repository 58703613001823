.board {
  width: 95%;
  background-color: black;
  border-radius: 6px;
  color: #fdc600;
  line-height: 0.9;
  margin-top: 10px;
}

.arrival-times {
  padding: 8px 0px;
}

.arrival-time {
  display: grid;
  grid-template-columns: 26px auto 94px;
  padding: 4px 10px;
  font-size: 26px;
  font-family: "dotmatrix";
  height: 31px;
}

.arrival-time .number-bullet {
  text-align: left;
}

.arrival-time .destination {
  overflow: hidden;
}

.arrival-time .destination p {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  /* animation: scroll-left 10s linear infinite; */
}

@keyframes scroll-left {
  0% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(-100px);
  }
}

.arrival-time .minutes {
  text-align: right;
}

.clock {
  font-size: 38px;
  font-family: "dotmatrix-bold";
}

.line-selector-container {
  width: 95%;
  font-family: rail-alphabet;
}

.line-selector-container .line-radio-group {
  width: 100%;
}

.line-selector-container .ant-radio-group-large .ant-radio-button-wrapper {
  background-color: rgb(36, 36, 36);
  color: white;
  width: 100%;
  font-size: 18px;
  padding: 0;
}

.line-selector-container
  .ant-radio-group-large
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: #1890ff !important;
  border-color: #1890ff;
  /* border-width: 5px; */
}

.line-selector-container
  .ant-radio-group-large
  .ant-radio-button-wrapper.Jubilee {
  background-color: #6a7278;
  color: #ffffff;
}

.line-selector-container
  .ant-radio-group-large
  .ant-radio-button-wrapper.Bakerloo {
  background-color: #894e24;
  color: #ffffff;
}

.line-selector-container
  .ant-radio-group-large
  .ant-radio-button-wrapper.District {
  background-color: #007229;
  color: #ffffff;
}

.line-selector-container .ant-radio-group-large .ant-radio-button-wrapper.DLR {
  background-color: #00afad;
  color: #000000;
}

.line-selector-container
  .ant-radio-group-large
  .ant-radio-button-wrapper.Hammersmith-and-City {
  background-color: #d799af;
  color: #000000;
}

.line-selector-container
  .ant-radio-group-large
  .ant-radio-button-wrapper.London-Overground {
  background-color: #e86a11;
  color: #000000;
}

.line-selector-container
  .ant-radio-group-large
  .ant-radio-button-wrapper.TFL-Rail {
  background-color: #001aa8;
  color: #ffffff;
}

.line-selector-container
  .ant-radio-group-large
  .ant-radio-button-wrapper.Waterloo-and-City {
  background-color: #77d1bd;
  color: #ffffff;
}

.line-selector-container
  .ant-radio-group-large
  .ant-radio-button-wrapper.Central {
  background-color: #dc2420;
  color: #ffffff;
}

.line-selector-container
  .ant-radio-group-large
  .ant-radio-button-wrapper.Circle {
  background-color: #ffce00;
  color: #000000;
}

.line-selector-container
  .ant-radio-group-large
  .ant-radio-button-wrapper.Metropolitan {
  background-color: #751057;
  color: #ffffff;
}

.line-selector-container
  .ant-radio-group-large
  .ant-radio-button-wrapper.Piccadilly {
  background-color: #0018a8;
  color: #ffffff;
}

.line-selector-container
  .ant-radio-group-large
  .ant-radio-button-wrapper.Victoria {
  background-color: #00a0e3;
  color: #000000;
}

.line-selector-container .ant-radio-group-large .ant-radio-button-wrapper.Tram {
  background-color: #66cc01;
  color: #ffffff;
}

.line-selector-container
  .ant-radio-group-large
  .ant-radio-button-wrapper.Northern {
  background-color: #000000;
  color: #ffffff;
}

.direction-selector-container {
  width: 95%;
  font-family: rail-alphabet;
}

.direction-selector-container .direction-radio-group {
  width: 100%;
  padding: 10px 0;
}

.direction-selector-container .ant-radio-group-large .ant-radio-button-wrapper {
  background-color: rgb(36, 36, 36);
  color: white;
  width: 50%;
  font-size: 18px;
  padding: 0;
}

.direction-selector-container
  .ant-radio-group-large
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: #1890ff;
  border-color: #1890ff;
}

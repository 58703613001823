.station-sign-container {
  font-size: 36px;
  font-family: rail-alphabet;
  color: white;
  width: 95%;
  margin: 10px 10px 0 10px;
}

.station-sign {
  border-radius: 6px;
  /* background-color: #27339f; */
  line-height: 1.3;
  display: grid;
  grid-template-columns: 20% 80%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.station-sign.dlr {
  background-color: #00b0af;
}

.station-sign.tube {
  background-color: #0012aa;
}

.station-sign .name {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 13px;
  padding-bottom: 8px;
}

.station-sign .logo {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: white;
}

.station-sign .logo img {
  width: 62px;
}

@import "antd/dist/antd.css";

@font-face {
  font-family: dotmatrix;
  src: url("./fonts/dotmatrix.ttf") format("truetype");
}

@font-face {
  font-family: dotmatrix-bold;
  src: url("./fonts/dotmatrix_bold.ttf") format("truetype");
}

@font-face {
  font-family: rail-alphabet;
  src: url("./fonts/rail_alphabet.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.station-selector-container {
  width: 95%;
  font-family: dotmatrix-bold;
  padding-top: 10px;
}

.station-selector-container .ant-select-single .ant-select-selector {
  border-radius: 15px;
}

.station-selector-container .ant-select-single .ant-select-selector {
  background-color: black;
  color: #fdc600;
}

.station-selector-container
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  text-align: left;
  color: #fdc600;
}

.station-selector-container .selector {
  width: 100%;
  font-size: 16px;
}

.station-selector-container .selector .ant-select-clear {
  background-color: unset;
  color: #fdc600;
  height: 32px;
  width: 47px;
  top: 20%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: unset;
}

.station-selector-container
  .selector
  .ant-select-selector
  .ant-select-selection-search {
  margin-right: 30px;
}
.dropdown-list-icon {
  height: 10px;
}
